import React from "react"

export default function About() {
    return (
        <div>
            <h1>About me</h1>
            <p>
                I’m good enough, I’m smart enough, and gosh darn it, people like me!
            </p>
        </div>
    )
}